<template>
	<v-layout row wrap justify-center align-center>
		<TasksManagerToolbarSorting class="shrink" />
		<ToggleButton
			v-if="$vuetify.breakpoint.mdAndUp"
			v-model="verticalDisplayMode"
			:icon-on="'view_quilt'"
			:icon-off="'vertical_split'"
			:tooltip="'actions.switch_display_mode'"
		/>
		<v-divider v-if="isAccountant" vertical />
		<TasksManagerActionAddButton v-if="isAccountant" :minimized="isMinimized" />
		<TasksManagerActionImportButton v-if="isAccountant" :minimized="isMinimized" />
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerToolbarActions',
	components: {
		TasksManagerToolbarSorting: () => ({
			component: import('@/components/TasksManager/Toolbar/TasksManagerToolbarSorting')
		}),
		TasksManagerActionAddButton: () => ({
			component: import('@/components/TasksManager/Actions/TasksManagerActionAddButton')
		}),
		TasksManagerActionImportButton: () => ({
			component: import('@/components/TasksManager/Actions/TasksManagerActionImportButton')
		}),
		ToggleButton: () => ({
			component: import('@/components/Commons/ToggleButton')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant
		}),
		verticalDisplayMode: {
			get: function () {
				return this.$store.state.tasks.verticalDisplayMode
			},
			set: function (value) {
				return this.service.setVerticalDisplayMode(value)
			}
		},
		isMinimized: function () {
			return this.$vuetify.breakpoint.lgAndDown || this.minimized
		}
	},
	created: function () {
		this.verticalDisplayMode = this.$vuetify.breakpoint.lgAndUp
	}
}
</script>
